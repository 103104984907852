html {
 background-color: #f9fafb;
 font-family: 'Fira Mono', monospace !important;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  /*top: 50%;*/
  bottom: -56px;

  /*display: block;*/

  /*-webkit-transform: translate(0, -50%);*/
  /*-ms-transform: translate(0, -50%);*/
  /*transform: translate(0, -50%);*/

  /*cursor: pointer;*/

  /*border: none;*/
  /*outline: none;*/
}
/* .slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
} */
/*.slick-prev:hover:before,*/
/*.slick-prev:focus:before,*/
/*.slick-next:hover:before,*/
/*.slick-next:focus:before*/
/*{*/
/*    opacity: 1;*/
/*}*/
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev {
  left: -12px;
}
[dir='rtl'] .slick-prev {
  left: 0;
  left: auto;
}

.slick-next {
  /*right: -25px;*/
  left: 100px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: '•';
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
